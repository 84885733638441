
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300; 400; 500; 600&display=swap'); @font-face { font-family: 'Averta Demo PE'; src: url('./fonts/AvertaDemoPE-Regular.woff2') format('woff2'),
 url('./fonts/AvertaDemoPE-Regular.woff') format('woff'); font-weight: normal; font-style: normal; font-display: swap; } 

@font-face {
  font-family: "Averta Demo PE";
  src: url("./fonts/AvertaDemoPE-ExtraboldItalic.woff2") format("woff2"),
    url("./fonts/AvertaDemoPE-ExtraboldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

body {
  font-family: "Rubik", sans-serif !important;
  overflow-x: hidden;
}
img {
  max-width: 100%;
}
* {
  margin: 0;
  padding: 0;
  outline: none;
  box-sizing: border-box;
  list-style: none;
}
.top-space {
  margin-top: 200px;
}
.navigation .MuiButtonBase-root a p {
  color: #fff;
  font-size: 18px;
  font-weight: 400;
  font-family: "Rubik";
}
.navigation .MuiButtonBase-root a {
  text-decoration: none;
}
.navigation li {
  position: relative;
  padding: 0 35px;
}
.navigation li::after {
  position: absolute;
  right: 0;
  content: "/";
  font-size: 23px;
  color: #474747;
}
.navigation li:last-child::after {
  content: "";
}
.MuiButtonBase-root.primary-btn {
  background: transparent linear-gradient(102deg, #07d5ff 0%, #0432c5 100%) 0%
    0% no-repeat padding-box;
  padding: 12px 12px 12px 12px;
  font-family: "Rubik";
  font-weight: 500;
  position: relative;
  z-index: 22;
}
.MuiButtonBase-root.primary-btn svg {
  margin: 0 0 0 12px;
}
.main_conainer {
  max-width: 1170px;
  margin: 0 auto;
  padding: 0 15px;
}
.header {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 222;
}
.headerbg {
  background: transparent linear-gradient(166deg, #0a0a0a 0%, #10518a 100%) 0%
    0% no-repeat;
  padding: 15px 0;
}
.HeroSection {
  position: relative;
  left: 0;
  right: 0;
  width: 100%;
  top: 0;
  text-align: center;
  padding: 160px 0 20px 0;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    149deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 130, 243, 1) 100%
  );
}

.MuiTypography-root.subtitle {
  color: #fff;
  font-size: 22px;
  font-weight: 500;
  font-family: "Rubik";
  position: relative;
  z-index: 22;
}
.MuiTypography-root.title {
  color: #fff;
  font-size: 60px;
  margin: 0 auto;
  font-family: "Averta Demo PE";
  font-style: italic;
}
.HeroSection .MuiTypography-root.title {
  color: #fff;
  max-width: 70%;
  margin: 30px auto;
  font-size: 60px;
  position: relative;
  z-index: 22;
}
.footer-outer {
  background: rgb(0, 0, 0);
  background: linear-gradient(
    149deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 130, 243, 1) 100%
  );
  padding: 100px 0 0 0;
}
.footer-outer .MuiButtonBase-root.secondaryBtn {
  border-color: #fff;
  color: #fff;
}
.footer-outer .contact-title {
  font-size: 16px;
  font-family: "Averta Demo PE";
  font-style: italic;
}
.footer-outer .contactNo {
  color: #8e8e8e;
  font-size: 14px;
  font-family: "Rubik";
  font-weight: 400;
}
.MuiTypography-root.Fsubtitle {
  color: #8e8e8e;
  font-family: "Rubik";
  font-size: 22px;
  font-weight: 400;
}
.MuiTypography-root.footerTitle {
  font-size: 60px;
  font-family: "Averta Demo PE";
  font-style: italic;
}

.MuiTypography-root.small-title {
  color: #fff;
  font-weight: 600;
}
.lottieanimation svg {
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  right: 0;
}
/* card css */
.hero-box-outer {
  display: flex;
  gap: 20px;
  margin-top: 150px;
}
.cardBox {
  text-align: left;
  display: flex;
  height: 100%;
  padding: 30px;
  background-color: rgba(0, 0, 0, 0.3);
}
.cardBox h2 {
  font-size: 35px;
  color: #fff;
  font-family: "Averta Demo PE";
  font-style: italic;
}
.firstBox.cardBox h3 {
  padding: 0 20px 0 0;
}
.cardBox h3 {
  font-size: 17px;
  margin: 20px 0 0 0;
  color: #fff;
  font-family: "Rubik";
  font-weight: 300;
}
.firstBox {
  background: transparent linear-gradient(217deg, #007377 0%, #141a2d 100%) 0%
    0% no-repeat padding-box;
}
.box-left {
  width: 100%;
}
.box-right {
  width: 60%;
}
.box-right img {
  position: absolute;
  right: 5px;
  width: 310px;
  bottom: 30px;
}
/* portfolio start */
.portfolio {
  margin: 100px 0;
}

.MuiTypography-root.small-title {
  font-size: 22px;
  color: #8e8e8e;
  font-family: "Rubik";
  font-weight: 400;
  letter-spacing: 0;
}
.MuiTypography-root.title {
  font-size: 50px;
  color: #000000;
  font-weight: 600;
  font-family: "Averta Demo PE";
  font-style: italic;
  margin: 0;
  letter-spacing: 0;
}
/* .portfolio-list { display: flex; flex-wrap: wrap; gap: 60px; } */
.portfolio-list li {
  width: 100%;
  flex-basis: 47%;
  margin-bottom: 80px;
}
.portfolio-detail {
  display: inline-block;
  margin: 20px 0 0 0;
}
.MuiTypography-root.tech-name {
  color: #8e8e8e;
  font-size: 18px;
  font-family: "Rubik";
  font-weight: 400;
}
.MuiTypography-root.app-name {
  color: #000;
  font-weight: 900;
  font-size: 32px;
  font-family: "Averta Demo PE";
  font-style: italic;
}
.portfolio-list li.secondLi {
  margin-top: 80px;
}
/* portfolio end */

/* about section start */
.about-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: solid 1px #ccc;
  padding: 0px 0 20px 0;
}
.about-top h2 {
  font-size: 12px;
  color: #0082f3;
  font-size: 40px;
  font-family: "Averta Demo PE";
  font-style: italic;
}
.MuiButtonBase-root.secondaryBtn {
  padding: 12px 20px;
  color: #000;
  font-weight: 500;
  text-transform: capitalize;
  font-size: 14px;
  border-color: #0082f3;
  font-family: "Rubik";
}
.MuiButtonBase-root.secondaryBtn svg {
  margin: 0 0 0 18px;
  color: #0082f3;
  font-size: 18px;
}
.aboutContent {
  display: flex;
  justify-content: space-between;
  padding: 50px 0 80px 0;
}
.aboutContent .left {
  width: 40%;
}
.aboutContent .left .MuiTypography-root.title {
  max-width: 80%;
}
.Asubtitle {
  font-size: 18px;
  color: #8e8e8e;
  font-weight: 400;
}
.MuiTypography-root.Atitle {
  color: #000000;
  font-weight: 600;
  margin: 18px 0 0 0;
  font-family: "Averta Demo PE";
  font-style: italic;
  font-size: 46px;
}
.aboutContent .Acaption {
  font-size: 16px;
  color: #515151;
  margin: 18px 0;
  font-family: "Rubik";
  font-weight: 400;
}
.aboutContent .right {
  background: url(./assets/images/map.svg) no-repeat;
  width: 60%;
  background-size: 100%;
  display: flex;
  align-items: center;
}
.aboutContent .right ul {
  background: rgba(255, 255, 255, 0.6);
  display: flex;
  flex-wrap: wrap;
  padding: 40px 30px;
  border-radius: 10px;
  width: 70%;
  margin: 0 auto;
  gap: 20px;
  justify-content: space-between;
}
.aboutContent .right ul li {
  text-align: center;
  width: 45%;
}
.aboutContent .right .aboutBigtext {
  color: #000;
  font-weight: 600;
  font-size: 30px;
  font-family: "Averta Demo PE";
  font-style: italic;
}

.aboutContent .about-text {
  font-size: 14px;
  color: #515151;
  text-align: center;
  font-family: "Rubik";
  font-weight: 400;
}

.blue-sec .MarResult {
  background: transparent linear-gradient(94deg, #07d5ff 0%, #0432c5 100%) 0% 0%
    no-repeat;
  border: transparent;
}
.blue-sec .MarResult ul {
  justify-content: space-around;
}
.blue-sec .MarResult li img {
  max-width: 50px;
  height: 50px;
}

/* marquee section start */
.MarResult {
  width: 55%;
  margin: 30px auto 0;
  border: solid 1px #dbdbdb;
  border-radius: 60px;
  padding: 20px;
}
.MarResult ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  width: 100%;
}
.marquee-sec {
  text-align: center;
}
.MuiTypography-root.mrTitle {
  font-weight: 400;
  font-size: 22px;
  font-family: "Rubik";
  letter-spacing: normal;
  line-height: normal;
}
.capability-sec {
  background: transparent linear-gradient(114deg, #0a0a0a 0%, #10518a 100%) 0%
    0% no-repeat padding-box;
  padding: 100px 0 40px 0;
  margin: 100px 0 0 0;
}
.capability-sec .title {
  color: #fff;
}
.box-cp {
  margin: 50px 0 0 0;
}
.box-cp ul {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: space-between;
}
.box-cp ul li {
  background-color: rgba(0, 0, 0, 0.1);
  border: solid 1px rgba(255, 255, 255, 0.2);
  padding: 50px 40px;
  border-radius: 30px;
  width: calc(25% - 20px);
}
.box-cp ul li .cbox-title {
  color: #fff;
  font-weight: 600;
  font-size: 26px;
  margin: 20px 0 0 0;
  font-family: "Averta Demo PE";
  font-style: italic;
}
.box-cp ul li .cbox-info {
  color: #fff;
  font-weight: 300;
  font-size: 12px;
  margin: 10px 0 0 0;
}
.cp-mrque {
  margin: 100px 0 0 0;
}
.cp-mrque .MuiTypography-root.mrTitle {
  color: #fff;
}
.cp-Abouttop {
  margin: 75px 0 0 0;
}
.cp-Abouttop .MuiButtonBase-root.secondaryBtn {
  color: #fff;
}
.cp-Abouttop .about-top {
  border-bottom: 0;
}

/* digital Exp section start */
.dgSection { padding: 100px 0; } 
.section-heading { text-align: center; max-width: 70%; margin: 0 auto; } 
.MuiTypography-root.detail { font-size: 16px; color: #515151; max-width: 90%; margin: 20px auto 0; letter-spacing: 0; } 
.gdlist { display: inline-block; margin: 80px 0 0 0; } 
.gdlist ul { display: flex; flex-wrap: wrap; gap: 12px; } 
.gdlist ul li { background: url(./assets/images/dg-li-shap.svg) no-repeat; background-size: 100%; width: calc(25% - 12px); height: 300px; display: flex; flex-flow: column; align-items: flex-start; justify-content: center; position: relative; padding: 20px 50px 20px 20px; overflow: hidden; } 
.gdlist .dgbox-title { font-weight: 600; font-family: 'Averta Demo PE'; font-style: italic; letter-spacing: 0; font-size: 22px; margin: 30px 0 0 0; } 
.gdlist .dgbox-info { color: #8E8E8E; font-size: 14px; letter-spacing: 0; font-weight: 400; font-family: 'Rubik'; margin: 20px 0 0 0; } 

/* journy Box start */
.journy-mainBox {
  display: inline-block;
  width: 100%;
  margin: 0 0 100px 0;
}
.journy-heading {
  margin: 80px 0 0 0;
}
.journy-reviewList {
  display: flex;
  gap: 16px;
  justify-content: center;
  margin: 50px 0 84px 0;
}
.journy-reviewList li {
  background: transparent linear-gradient(92deg, #cfebff 0%, #ffffff 100%) 0% 0%
    no-repeat padding-box;
  width: 206px;
  height: 120px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}
.journy-reviewList li .revTitle {
  color: #000;
  font-family: "Rubik";
  font-weight: 600;
  font-size: 22px;
}

.journy-reviewList li .revdisc {
  color: #8e8e8e;
  font-family: "Rubik";
  font-weight: 400;
  font-size: 16px;
}

/* testimonial section */
.testimonial-mainBox {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0 0 190px 0;
  background: transparent linear-gradient(108deg, #cfebff 0%, #ffffff 100%) 0%
    0% no-repeat;
  margin: 80px 0 0 0;
}
.inside-test {
  display: flex;
}
.testimonial-mainBox .testmonial-left {
  width: 50%;
  padding: 100px 100px 0 0;
}
.testimonial-mainBox .testmonial-left .section-heading {
  text-align: left;
  max-width: 100%;
}
.testimonial-mainBox .testmonial-left .detail {
  max-width: 100%;
}
.testimonial-mainBox .testmonial-left .secondaryBtn {
  margin: 40px 0 0 0;
}
.testimonial-mainBox .testmonial-right {
  width: 50%;
  background-color: #fff;
  box-shadow: 0px 3px 20px #bcbcbc29;
  border-radius: 0 0 10px 10px;
  display: flex;
  flex-flow: column;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 0 76px 60px 40px;
}
.testimonial-mainBox .testmonial-right .testReview {
  font-size: 22px;
  font-weight: 400;
  font-family: "Rubik";
  line-height: normal;
  letter-spacing: 0;
  margin: 0 0 35px 0;
}
.testimonial-mainBox .testmonial-right .tusername {
  font-size: 22px;
  font-weight: 600;
  font-family: "Averta Demo PE";
  font-style: italic;
}
.testimonial-mainBox .testmonial-right .tuserdesig {
  color: #6b6b6b;
  font-size: 16px;
  font-weight: 400;
  font-family: "Rubik";
  line-height: normal;
  letter-spacing: 0;
}
.testimonial-mainBox .testmonial-right .user-profiles {
  display: flex;
  gap: 20px;
}
.testimonial-mainBox .testmonial-right .user-profiles li img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 100%;
  overflow: hidden;
  margin: 30px 0 0 0;
}
/* bottom cards */
.bottomCards {
  position: relative;
  display: flex;
  gap: 15px;
}
.bottomCards .cardBox h2 {
  font-size: 22px;
}
.bottomCards .cardBox h3 {
  color: #fff;
  font-size: 16px;
  margin: 10px 0 0 0;
}
.bottomCards .insideBox {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  width: 100%;
  position: absolute;
  bottom: -50px;
}
.bottomCards .firstcard h2 {
  color: #000;
}
.bottomCards .firstcard h3 {
  color: #8e8e8e;
}
.bottomCards .firstcard {
  background-color: #fff;
}

.bottomCards .secondcard {
  background: transparent linear-gradient(133deg, #007377 0%, #141a2d 100%) 0%
    0% no-repeat padding-box;
}

.bottomCards .thirdcard {
  background: transparent linear-gradient(105deg, #a73f92 0%, #141a2d 100%) 0%
    0% no-repeat padding-box;
}

/* contact us page */
.contactus_sec {
  background: url(./assets/images/map.svg) no-repeat right top;
  width: 100%;
  background-size: 60%;
  margin: 0 0 200px 0;
}
.contact_inside {
  display: flex;
}
.contactus_left {
  width: 50%;
}
.contactus_left .section-heading {
  max-width: 100%;
  text-align: left;
}
.contact_info li {
  margin: 30px 0;
}
.contactus_left .ct-small {
  color: #8e8e8e;
  font-size: 16px;
  font-family: "Rubik";
}
.contactus_left .ct-title,
.contactus_left .ct-title a {
  color: #000;
  font-size: 18px;
  font-family: "Rubik";
  font-weight: 500;
  text-decoration: none;
}
.contact-right {
  background-color: rgba(255, 255, 255, 0.6);
  width: 50%;
  padding: 70px;
}
.form-fildes {
  display: inline-block;
  width: 100%;
  margin: 0 0 30px 0;
}
.contact-right label {
  display: block;
  width: 100%;
  color: #a7a7a7;
  font-size: 14px;
  text-transform: uppercase;
  font-family: "Rubik";
  font-weight: 500;
}
.contact-right .input-field {
  width: 100%;
  height: 45px;
  border: solid 1px #c6c6c6;
  padding: 5px 10px;
  font-size: 18px;
  font-family: "Rubik";
  font-weight: 500;
  margin: 10px 0 0 0;
}

.contact-right textarea {
  width: 100%;
  border: solid 1px #c6c6c6;
  padding: 5px 10px;
  font-size: 18px;
  font-family: "Rubik";
  font-weight: 500;
  margin: 10px 0 0 0;
  resize: none;
  height: 100px;
}
.boxed {
  border: solid 1px #c6c6c6;
  margin: 10px 0 0 0;
}
.boxed label {
  display: inline-block;
  width: 33%;
  padding: 10px;
  transition: all 0.3s;
  color: #8b8b8b;
  border-right: solid 1px #d5d5d5;
  padding: 14px 5px;
  text-align: center;
  cursor: pointer;
}
.boxed label:last-child {
  border-right: none;
}
.boxed input[type="radio"] {
  display: none;
}

.boxed input[type="radio"]:checked + label {
  color: #0082f3;
}

/* portfolio page start */
.project-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  flex-wrap: wrap;
  margin: 120px 0 0;
}
.project-list li {
  width: calc(50% - 8px);
}
.project-icon {
  display: flex;
  align-items: center;
}
.project-icon img {
  width: 72px;
  height: 72px;
}
.tabscenter .MuiTabs-flexContainer {
  justify-content: center;
  align-items: flex-end;
  height: 100px;
  margin: 50px 0 0 0;
}
.tabscenter .MuiTab-root {
  position: relative;
  overflow: visible;
  font-size: 14px;
  font-family: "Rubik";
  font-weight: 500;
  text-transform: capitalize;
}
.tabscenter .MuiTab-root:before {
  position: absolute;
  content: "";
  width: 70px;
  height: 70px;
  top: -40px;
  left: 12px;
  right: 0;
  margin: 0 auto;
}
.tabscenter .MuiTab-root#simple-tab-0:before {
  background: url(./assets/images/hart.png) no-repeat;
  background-size: 70%;
}
.tabscenter .MuiTab-root#simple-tab-1:before {
  background: url(./assets/images/rup.png) no-repeat;
  background-size: 70%;
}
.tabscenter .MuiTab-root#simple-tab-2:before {
  background: url(./assets/images/enterprise.png) no-repeat;
  background-size: 70%;
}
.tabscenter .MuiTab-root#simple-tab-3:before {
  background: url(./assets/images/cart.png) no-repeat;
  background-size: 70%;
}
.tabscenter .MuiTab-root#simple-tab-4:before {
  background: url(./assets/images/car.png) no-repeat;
  background-size: 70%;
}
.tabscenter .MuiTab-root#simple-tab-5:before {
  background: url(./assets/images/book.png) no-repeat;
  background-size: 70%;
}
.tabscenter .MuiTab-root#simple-tab-6:before {
  background: url(./assets/images/social.png) no-repeat;
  background-size: 70%;
}
/* .portfolio-sec { margin: 120px 0 0 0; padding: 80px 0 0 0; } */

/* about us page start */
.MuiTypography-root.aboutimg {
  margin: 100px 0 0 0;
}
.loop-content .looptext {
  font-size: 80px;
  background: -webkit-linear-gradient(#007377, #141a2d);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
  font-family: "Averta Demo PE";
}
.whyus {
  background: transparent linear-gradient(108deg, #cfebff 0%, #ffffff 100%) 0%
    0% no-repeat padding-box;
  padding: 70px 0;
  margin: 100px 0 0 0;
}
.expbox {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
  margin: 60px 0 0 0;
}
.expbox li a {
  transition: all 0.3s;
  padding: 20px 12px;
  display: block;
  text-decoration: none;
  color: #000;
  font-family: "Averta Demo PE";
  text-align: center;
}
.expbox li a:hover {
  background-color: #fff;
}
.expbox li a .number {
  color: #0082f3;
  font-size: 40px;
}
.expbox li a .heading {
  font-size: 18px;
  font-weight: 600;
  font-style: italic;
}
.expbox li a .disc {
  font-size: 12px;
  font-family: "Rubik";
  color: #515151;
  max-width: 170px;
}
.coreValue-sec {
  padding: 100px 0;
}
.valuesec-inside {
  display: flex;
  justify-content: space-between;
}
.valuesec-inside .section-heading {
  text-align: left;
  max-width: 100%;
}
.value-left {
  width: 40%;
  text-align: left;
}
.value-left .MuiTypography-root.detail {
  max-width: 100%;
}
.value-right {
  width: 40%;
}
.value-right .icon {
  flex-shrink: 0;
  width: 50px;
  height: 50px;
  margin: 12px 0 0 0;
}
.value-right ul {
  display: flex;
  flex-flow: column;
  row-gap: 60px;
}
.value-right ul li {
  display: flex;
  gap: 30px;
  align-items: flex-start;
}
.value-right ul li .title {
  font-weight: 600;
  font-size: 26px;
}
.value-right ul li .discrip {
  font-size: 14px;
  color: #515151;
}
.jorny-sec {
  background: transparent linear-gradient(89deg, #0a0a0a 0%, #10518a 100%) 0% 0%
    no-repeat;
  color: #fff;
  padding: 100px 0;
  margin: 0 0 60px 0;
}
.jorny-sec .section-heading {
  text-align: left;
  margin: 0;
}
.jorny-sec .MuiTypography-root.small-title,
.jorny-sec .MuiTypography-root.title {
  color: #fff;
}
.jorny-sec .MuiTypography-root.detail {
  max-width: 100%;
  color: #fff;
}
.steps {
  display: flex;
  gap: 5px;
}
.jorny-sec .steps .count {
  font-weight: 600;
  font-family: "Averta Demo PE";
  font-size: 16px;
  margin: 0 0 16px 0;
}

.jorny-sec .steps li:nth-child(odd) .yearbox {
  background: transparent linear-gradient(103deg, #a73f92 0%, #141a2d 100%) 0%
    0% no-repeat;
  display: inline-block;
  width: 190px;
  height: 70px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-family: "Averta Demo PE";
  font-size: 16px;
  position: relative;
}
.steps li {
  text-align: center;
}
.steps li:nth-child(1) {
  margin-top: 190px;
}
.steps li:nth-child(2) {
  margin-top: 160px;
}
.steps li:nth-child(3) {
  margin-top: 120px;
}
.steps li:nth-child(4) {
  margin-top: 80px;
}
.steps li:nth-child(5) {
  margin-top: 40px;
}
.jorny-sec .steps li:nth-child(even) .yearbox {
  background: transparent linear-gradient(103deg, #007377 0%, #141a2d 100%) 0%
    0% no-repeat;
  display: inline-block;
  width: 190px;
  height: 70px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.steptext {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.MuiTypography-root.start,
.MuiTypography-root.sucsess {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 12px;
  color: #b7b7b7;
  margin: 10px 0 0 0;
}
.MuiTypography-root.sucsess {
  margin-top: -350px;
  color: #2aff9c;
}

/* Market place css start */
.market-hero {
  background: url(./assets/images/marketplace-bg.png);
  height: 80vh;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.market-hero .small-title {
  color: #fff;
  font-size: 22px;
  font-weight: 300;
  font-family: "Rubik";
  position: relative;
  z-index: 22;
}
.market-hero .title {
  color: #fff;
  margin: 30px auto;
  font-size: 60px;
  position: relative;
  z-index: 22;
}
.market-hero .small-disc {
  color: #fff;
  font-family: "Rubik";
  font-weight: 300;
  font-size: 18px;
}
.tabhaed {
  display: flex;
  align-items: center;
  max-width: 60%;
  margin: 0 auto;
  background: #fff;
  height: 80px;
  border-radius: 50px;
  border: 1px solid #dbdbdb;
  justify-content: center;
  top: -50px;
  position: relative;
  box-shadow: 0px 2px 2px #e2ddddc4;
}
.filtertext {
  font-weight: 500;
  color: #054dde;
}
.tabhaed button.MuiTab-root {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 2px #e2ddddc4;
  border-radius: 30px;
  margin: 0 10px 10px 10px;
}
.tabhaed .css-1aquho2-MuiTabs-indicator {
  background-color: transparent;
}
.webapp-box {
  display: flex;
  gap: 46px;
  margin-bottom: 50px;
}
.webapp-img {
  width: 40%;
}
.webapp-detail {
  width: 60%;
}
.webapp-box .webapp-Title {
  font-family: "Averta Demo PE";
  font-style: italic;
  font-weight: 500;
  font-size: 35px;
}
.webapp-box .appsumry {
  display: flex;
  align-items: center;
  gap: 20px;
  margin: 10px 0 0 0;
}
.webapp-box .appsumry li {
  font-size: 14px;
  color: #8e8e8e;
  font-family: "Rubik";
  font-weight: 400;
  position: relative;
}
.webapp-box .appsumry li::after {
  position: absolute;
  content: "|";
  right: -10px;
  font-size: 12px;
  top: 3px;
}
.webapp-benifits li {
  font-size: 16px;
  font-family: "Rubik";
  font-weight: 400;
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
}
.webapp-box .appsumry li:last-child:after {
  content: "";
}
.webapp-benifits li svg {
  color: #0446cc;
  font-size: 13px;
}
.webapp-benifits {
  margin: 20px 0;
}
.rating span svg {
  color: #f9bf00;
}

/* service page */
.serviceBox {
    margin: 117px 0 0 0;
}
.service-headingSec {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 50px 0 100px 0;
}
.serviceBox .headingtop {
    width: 50%;
    font-size: 50px;
    font-weight: 400;
    font-family: 'Averta Demo PE';
    font-style: italic;
}
.serviceBox .servicetopDetl {
    width: 30%;
}
.serviceBox .servicetopDetl p{
    margin: 0 0 30px 0;
    font-size: 16px;
    color: #515151;
    font-family: 'Rubik';
    font-weight: 400;
}
.headingleft .headingtitle {
    font-size: 30px;
    margin: 20px 0 0 0;
}
.headingleft .headsubtitle {
    font-size: 18px;
    font-family: 'Rubik';
    font-weight: 400;
    margin: 10px 0 0  0;
}
.technoSection {
    padding: 82px 0 82px;
}
.technoSection:nth-child(even){
    background: transparent linear-gradient(125deg, #CFEBFF 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
}
.technohead {
    display: flex;
    align-items: center;
    gap: 274px;
    justify-content: space-between;
}
.headingleft img {
    max-width: 60px;
    width: 100%;
}
.headingleft {
    max-width: 30%;
    width: 100%;
}
.headingright {
    max-width: 40%;
    width: 100%;
}
.headingright p{
    color: #515151;
    font-size: 16px;
}
.headingright ul{padding: 0; margin: 20px 0 0 0;}
.headingright ul li{color:#515151;font-size:16px;position: relative;margin: 10px 0;padding: 0 0 0 20px;}
.headingright ul li:before {
    content: "";
    position: absolute;
    left: 0;
    top: 5px;
    width: 4px;
    height: 7px;
    border: solid 1px #0082F3;
}
.box-cp.service_cp ul li {
    background: #fff;
}
.box-cp.service_cp ul li .cbox-title{color: #000;}
.box-cp.service_cp ul li .cbox-info{color: #000;}
.serv-casestudy .portfolio-list{display: flex;gap:16px;}
.serv-casestudy .portfolio-list li img {
    height: 365px;
    width: 100%;
}
.serv-casestudy .portfolio-detail .MuiTypography-root.app-name {
    font-size: 22px;
    font-weight: 500;
    max-width: 90%;
    width: 100%;
}
.serv-casestudy .MuiTypography-root.tech-name{font-size: 16px;}
.box-cp.service_cp {
    margin: 80px 0 0 0;
}
.technoSection:nth-child(odd) .box-cp.service_cp ul li {
    border: solid 1px #D1D1D1;
}
.serv-casestudy{margin:80px 0 0 0;}
.css-ph2xsj-MuiTypography-root.casestydyTitle {
    font-family: 'Averta Demo PE';
    font-size: 25px;
    font-weight: 600;
    margin: 0 0 30px 0;
}
.box-cp.service_cp ul li img {
    max-width: 40px;
}


.piano {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 10px;
  }
  
  .key {
    background-color: red;
    width: 200px;
    height: 200px;
  }
  
  .marquee-container {
    white-space: nowrap;
    overflow: hidden;
  }
  
  .marquee-content {
    display: inline-block;
    animation: marquee 10s linear infinite;
    width: 100% !important;
  }
  
  @keyframes marquee {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  
  .marquee-content img {
      width: 46px;
      height: auto;
  }

/* media css start */
@media (max-width:991px){
 .main_conainer { padding: 0 20px; } 
.HeroSection .MuiTypography-root.title { font-size: 40px; } 
.hero-box-outer { flex-flow: column; margin-top: 70px; } 
.MuiCard-root { width: 100% !important; } 
.box-right { display: none; } 
.MuiTypography-root.app-name { font-size: 18px; } 
.MuiTypography-root.tech-name { font-size: 16px; } 
.about-top h2 { font-size: 30px; width:50%; } 
.aboutContent { display: inline-block; } 
.aboutContent .left, .aboutContent .right { width: 100%; text-align: center; } 
.aboutContent .left .MuiTypography-root.title { max-width: 100%; font-size: 30px; } 
.aboutContent .right ul, .MarResult { width: 100%; } 
.portfolio { margin: 50px 0; } 
.portfolio-list li { margin-bottom: 30px; } 
.box-cp ul { flex-flow: column; } 
.box-cp ul li { width: 100%; text-align: center; } 
.gdlist ul li { width: calc(50% - 12px); height: auto; background: transparent; } 
.box-cp ul li .cbox-info { font-size: 16px; } 
.journy-reviewList { flex-wrap: wrap; } 
.MuiTypography-root.title { font-size: 32px; } 
.section-heading { max-width: 100%; } 
.inside-test { flex-flow: column; } 
.testimonial-mainBox .testmonial-left { width: 100%; padding: 100px 0px 0 0; } 
.testimonial-mainBox .testmonial-right { width: 100%; padding: 40px 40px 60px 40px; margin: 30px 0 0 0; } 
.bottomCards .firstcard { background-color: #fff; border: solid 1px #d5d5d5; } 
.testimonial-mainBox { padding: 0 0 50px 0; } 
.bottomCards .insideBox { position: inherit; bottom: 0; flex-flow: column; margin: 30px 0; } 
.footer-outer { padding: 20px 0 0 0; } 
.MuiTypography-root.footerTitle { font-size: 32px; } 
.footer-outer .css-15jqxg1 { flex-wrap: wrap; } 
.contact_inside { display: flex; flex-flow: column; } 
.contactus_left { width: 100%; } 
.contact-right { width: 100%; padding: 0; } 
.contactus_sec { background-size: cover; margin: 0 0 0px 0; background-position: center center; } 
 .coreValue-sec { padding: 40px 0; } 
.value-right ul { row-gap: 20px; } 
 .valuesec-inside { flex-flow: column; } 
 .value-left,.value-right { width: 100%; } 
 .steps { flex-wrap: wrap; gap: 20px; } 
 .steps li:nth-child(1),.steps li:nth-child(2),.steps li:nth-child(3),
 .steps li:nth-child(4),.steps li:nth-child(5),.steps li:nth-child(6) { margin-top: 0; } 
 .steps li { width: calc(50% - 20px); } 
.jorny-sec .steps li:nth-child(odd) .yearbox, .jorny-sec .steps li:nth-child(even) .yearbox { width: 100%; } 
.steptext { display: none; } 
.expbox { flex-wrap: wrap; justify-content: center; } 
.valuesec-inside .section-heading { margin-bottom: 40px; } 
 }




