/* CSS FOR SCROLLING TEXT IN ABOUT SECTION  START */

/* Global resets and basic layout styling. PLEASE IGNORE! */
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@900&display=swap');

/* *{
  margin:0;
  overflow-x:hidden
} */
.spacer{
  width:100vw;
  height:101vh;
  background-color:black;
  display:flex;
  justify-content:center;
  align-items:center;
  overflow:hidden;
  text-transform: uppercase;
  text-align:center
}

.spacer > h1{
  position:absolute;
  color:transparent;
  width:500vw;
  font-size:5em;
  font-weight:900;
  font-family:'Raleway';
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: white;
}

.spacer h2{
  font-family:'Raleway';
  font-weight:900;
  font-size:3em;
  color:white;
  
  
}
.imageWrapper{
  position:absolute;
  width:250px;
  height:350px;
  background-color:transparent;
  overflow:hidden;
  left:10%;
  margin-top:0%;

}
/* img{
  width:100%;
  height:100%;
  object-fit:cover
} */

.spacer:nth-child(even){
  background-color:#121212
  
}

/* CSS FOR SCROLLING TEXT IN ABOUT SECTION  END */


/* CSS FOR PORTFOLIO SECTION IN HOME PAGE  START */


.piano {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 10px;
}

.key {
  background-color: red;
  width: 200px;
  height: 200px;
}

/* CSS FOR PORTFOLIO SECTION IN HOME PAGE  END */


/* Infinate Marquee start */

.b--marque-a{
  display: flex;
  overflow: hidden;
  width: 100%;
  column-gap: 0rem;
  text-decoration: none;
  &__content{
      display: flex;
      min-width: 100%;
      flex: 0 0 auto;
      column-gap: 0rem;
      justify-content:space-around;
      text-decoration: none;
  }
}
/* Infinate Marquee end */


